import cn from 'classnames'
import { gsap } from 'gsap'
import { useNextSanityImage } from 'next-sanity-image'
import Img from 'next/image'
import * as React from 'react'

import sanityClient from '../sanity'
import { duration, stagger } from '../utils/animation'
import imageUrlFor from '../utils/imageUrlFor'
import splitText from '../utils/splitText'
import Button from './Button'

type Props = {
  data: any
}

const Image = (props: Props) => {
  const { data } = props

  const imageProps = useNextSanityImage(sanityClient, data.image, {
    imageBuilder: () => imageUrlFor(data.image).ignoreImageParams().width(1400).fit('fill'),
  }) as any

  return (
    <div className="">
      <div className="js-imgWrap img-wrap overflow-hidden">
        <div className="img-inner relative md:h-[77vh] min-w-full">
          <Img
            {...imageProps}
            className="object-cover md:h-[77vh] min-w-full"
            alt={data.image.alt || 'Justdrive - bil i 120 dage.'}
            placeholder="blur"
            blurDataURL={imageUrlFor(data.image).ignoreImageParams().width(100).blur(80).url()}
          />
          {/* <img
            src={imageUrlFor(data.image).ignoreImageParams().width(1400).fit('fill').url()}
            className="object-cover md:h-[77vh] min-w-full"
            alt={data.image.alt || 'Justdrive - bil i 120 dage.'}
          /> */}
        </div>
      </div>
    </div>
  )
}

const Text = (props: Props) => {
  const { data } = props
  const ltf = data.imageOnTheLeft
  const dm = data.darkMode

  return (
    <div className={cn({ 'mt-10 md:mt-0 md:text-right': ltf })}>
      <div className={cn('flex flex-col justify-between h-full', { 'md:items-end': ltf })}>
        <div className="">
          <p className="js-splitText">{data.tagline}</p>
          <h2 className="js-splitText text-4xl md:text-5xl lg:text-7xl md:max-w-[600px]">{data.heading}</h2>
        </div>

        <div className={cn('flex flex-col items-start mt-8 md:mt-0', { 'md:items-end': ltf })}>
          <p className="js-splitText text-md md:max-w-lg">{data.excerpt}</p>

          {data.link && data.link.title ? <Button data={data.link} darkMode={dm} className="mt-10" /> : null}
        </div>
      </div>
    </div>
  )
}

const ImageWithText = (props: Props) => {
  const comp: undefined | React.Ref<HTMLElement> = React.useRef()

  const { data } = props
  const ltf = data.imageOnTheLeft
  const dm = data.darkMode

  React.useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const curRef = comp.current

      const [lines, wrappers] = splitText('.js-splitText', curRef)

      lines.lines.forEach(el => {
        gsap.from(el, {
          y: '100%',
          opacity: 0,
          delay: 0.2,
          duration: duration,
          stagger: stagger,
          scrollTrigger: {
            trigger: el,
            start: 'top 90%',
          },
          // onComplete: () => {
          //   lines.revert()
          //   wrappers.revert()
          // },
        })
      })

      gsap.from('.img-inner', {
        '--y': '100%',
        ease: 'power2.out',
        duration: 0.6,
        opacity: 0,
        scrollTrigger: {
          trigger: '.js-imgWrap',
          start: 'top 60%',
        },
      })
    }, comp)

    return () => {
      ctx.revert()
    }
  }, [])

  return (
    <section
      ref={comp}
      className={cn('imageWithText flex py-16', {
        'items-end': ltf,
        'bg-[#1D1D1B] text-white': dm,
        '': !dm,
      })}
    >
      <div className={cn('w-full md:grid grid-cols-2 gap-8 px-4 md:px-8', { 'flex flex-col-reverse': !ltf })}>
        {ltf ? (
          <>
            <Image {...props} />
            <Text {...props} />
          </>
        ) : (
          <>
            <Text {...props} />
            <Image {...props} />
          </>
        )}
      </div>
    </section>
  )
}

export default ImageWithText
