import { gsap } from 'gsap'
import * as React from 'react'

import { duration, stagger } from '../utils/animation'
import splitText from '../utils/splitText'
import NewsletterForm from './NewsletterForm'

type Props = {
  data: any
}

const Newsletter = (props: Props) => {
  const { data } = props

  const comp: undefined | React.Ref<HTMLElement> = React.useRef()

  // ---

  React.useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const curRef = comp.current

      const [lines] = splitText('.js-splitText', curRef)

      lines.lines.forEach(el => {
        gsap.from(el, {
          y: '100%',
          opacity: 0,
          delay: 0.2,
          duration: duration,
          stagger: stagger,
          scrollTrigger: {
            trigger: el,
            start: 'top 90%',
          },
        })
      })
    }, comp)

    return () => {
      ctx.revert()
    }
  }, [])

  // ---

  return (
    <section ref={comp} className="newsletter my-32 md:my-56 px-4 md:px-8">
      <h2 className="js-splitText text-4xl md:text-7xl lg:w-2/5">{data.title}</h2>

      <div className="w-full md:grid grid-cols-2 gap-8">
        <div className="flex flex-col justify-end md:max-w-1/2 my-10 md:my-0">
          <p className="js-splitText">{data.text}</p>
        </div>

        <NewsletterForm />
      </div>
    </section>
  )
}

export default Newsletter
