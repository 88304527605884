export const sanityListingsQuery = `*[_type == "listing" && !(_id match "drafts.")] {
  _id,
  _createdAt,
  slug,
  title,
  listing,
  cover,
  images,
}[0...50]
`

export const sanityThreeListingsQuery = `*[_type == "listing" && !(_id match "drafts.")] {
  _id,
  slug,
  title,
  listing,
  cover,
  images,
}
`

export const sanityListingQuery = `*[_type == "listing" && slug.current == $slug] [0]`
export const sanityListingFromIDQuery = `*[_type == "listing" && listing == $id] [0]`
