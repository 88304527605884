import cn from 'classnames'
import { gsap } from 'gsap'
import Link from 'next/link'
import * as React from 'react'

import { desert, duration, stagger } from '../utils/animation'
import imageUrlFor from '../utils/imageUrlFor'
import splitText from '../utils/splitText'
import Button from './Button'

type Props = {
  data: any
}

const Hero = (props: Props) => {
  const comp: undefined | React.Ref<HTMLElement> = React.useRef()
  const { data } = props

  React.useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const tl = gsap.timeline({ delay: 0.3 })

      splitText('.hero-title')
      splitText('.hero-caption')

      // STEP: Image
      tl.from('.js-hero-imgWrap .img-inner', {
        '--y': '100%',
        ease: 'power4.inOut',
        duration: 1.4,
      })

      tl.from(
        '.js-hero-imgWrap img',
        {
          y: 300,
          ease: 'power2.out',
          duration: 1.6,
        },
        '<'
      )

      tl.from(
        '.js-hero-imgWrap img',
        {
          scale: 1.1,
          ease: 'power1.inOut',
          duration: 1.6,
        },
        '<+=0.3'
      )

      // STEP: Text
      tl.from(
        '.hero-title-line',
        {
          y: '100%',
          opacity: 0,
          // ease: 'power2.out',
          duration: duration,
          stagger: stagger,
        },
        '>-=0.7'
      )

      tl.from(
        '.hero-caption-line',
        {
          y: '100%',
          opacity: 0,
          duration: duration,
          stagger: stagger,
        },
        `<+=${desert}`
        // '<+=0.5'
      )

      tl.from(
        '.hero-cta',
        {
          y: 20,
          opacity: 0,
          duration: duration,
        },
        `<+=${desert}`
      )
    }, comp)

    return () => {
      ctx.revert()
    }
  }, [])

  return (
    <section ref={comp} className="heroWithImage md:h-screen flex flex-reverse md:items-end bg-gradient-to-b from-olive-500">
      <div className="w-full flex flex-col-reverse md:grid grid-cols-2 gap-8 px-4 md:px-8 pt-20 md:pt-0">
        <div className="col-span-1">
          <div className="js-hero-imgWrap img-wrap overflow-hidden">
            <div className="img-inner">
              <img
                src={imageUrlFor(data.image).ignoreImageParams().width(1600).fit('fill').quality(75).format('webp').url()}
                className="object-cover md:h-[77vh] min-w-full"
                alt={data.image.alt}
              />
            </div>
          </div>
        </div>

        <div className="col-span-1 md:text-right">
          <div className="flex flex-col justify-between h-full">
            <h1
              className="hero-title text-3xl md:text-7xl lg:text-[4.2vw] mt-4"
              dangerouslySetInnerHTML={{ __html: data.heading }}
            ></h1>

            <div className="mb-6">
              <p className="hero-caption text-md md:max-w-md mr-0 ml-auto mb-8 md:mb-16 mt-6 md:mt-0">
                {data.text.split('\n').map((el, i) => (
                  <span key={i}>{el}</span>
                ))}
              </p>

              <div className="text-left">
                <Button
                  data={{
                    title: 'Se vores biler',
                    kind: 'green',
                    route: '/listing',
                  }}
                  className="hero-cta inline-flex"
                />
              </div>

              {/* <Link legacyBehavior href="/listing">
                <a className="hero-cta inline-flex items-center bg-green-600 text-black py-1 px-4 rounded-full hover:tracking-wide transition-[letter-spacing] ease-out">
                  <span>Se vores biler</span>
                  <svg width="98" height="14" viewBox="0 0 98 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-4">
                    <path
                      d="M0.761658 12.5127H85.7617L97.2617 1.0127M97.2617 1.0127V13.0127M97.2617 1.0127H85.2617"
                      stroke="black"
                    />
                  </svg>
                </a>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
