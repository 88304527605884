import cn from 'classnames'
import { gsap } from 'gsap'
import * as React from 'react'

import { desert, duration, stagger } from '../utils/animation'
import splitText from '../utils/splitText'
import Button from './Button'

type Props = {
  data: any
}

const SmallHero = (props: Props) => {
  const comp: undefined | React.Ref<HTMLElement> = React.useRef()
  const { data } = props

  const dm = data.darkMode

  React.useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const curRef = comp.current

      const tl = gsap.timeline({ delay: 0.3 })

      splitText('.js-heroTitle', curRef)
      splitText('.js-heroCaption', curRef)

      tl.from('.js-heroTitle-line', {
        y: '100%',
        opacity: 0,
        // ease: 'power2.out',
        duration: duration,
        stagger: stagger,
      })

      tl.from(
        '.js-heroCaption-line',
        {
          y: '100%',
          opacity: 0,
          duration: duration,
          stagger: stagger,
        },
        `<+=${desert}`
        // '<+=0.5'
      )

      tl.from(
        '.button',
        {
          y: 20,
          opacity: 0,
          duration: duration,
        },
        `>-=${stagger}`
        // `<+=${desert * 4}`
      )
    }, comp)

    return () => {
      ctx.revert()
    }
  }, [])

  return (
    <section
      ref={comp}
      className={cn(
        'smallHero relative flex items-center justify-center overflow-hidden md:h-[80vh] px-4 md:px-8 py-20 md:py-40',
        {
          'bg-[#1D1D1B] text-white': dm,
        }
      )}
    >
      <div className="z-10 flex flex-col items-center">
        <h1 className="js-heroTitle text-4xl md:text-7xl max-w-[1024px]">{data.title}</h1>
        {data.text && <p className="js-heroCaption mt-16 mb-10 max-w-[550px] text-center">{data.text}</p>}

        {data.link?.title && <Button data={data.link} darkMode={dm} />}
      </div>
    </section>
  )
}

export default SmallHero
