import { PortableText } from '@portabletext/react'
import cn from 'classnames'
import React from 'react'

import Image, { SanityImage } from './Image'

export type CardProps = {
  image: SanityImage
  title: string
  text: any
}

type Props = {
  data: CardProps
  className?: string
  style?: React.CSSProperties
}

const Card = (props: Props) => {
  return (
    <div className="md:min-h-[660px] p-4 md:p-6 [&:nth-of-type(6n+1)]:bg-neutral-200 even:bg-neutral-100 [&:nth-of-type(6n+3)]:bg-neutral-500 [&:nth-of-type(6n+3)]:text-white [&:nth-of-type(6n+5)]:bg-[#1D1D1B] [&:nth-of-type(6n+5)]:text-white">
      {props.data.image && (
        <>
          <Image src={props.data.image} width={800} className="aspect-[5/4]" parentClassName="mb-4" />
        </>
      )}

      <h3 className={cn(!props.data.image ? 'text-md mb-10' : 'text-2xl mb-8')}>{props.data.title}</h3>

      <div className={cn(props.data.image ? 'text-md' : 'text-xl lg:text-2xl')}>
        <PortableText value={props.data.text} />
      </div>
    </div>
  )
}

export default Card
