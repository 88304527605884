import { PortableText } from '@portabletext/react'
import { SanityImageAssetDocument } from '@sanity/client'
import cn from 'classnames'
import React from 'react'

import Card, { CardProps } from './Card'

type Props = {
  data: CardProps[]
}

const CardSection = (props: any) => {
  return (
    <div className="cardSection group grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 md:gap-8 p-4 md:p-8">
      {props.data.cards.map((el, i) => {
        return <Card key={i} data={el} />
      })}
    </div>
  )
}

export default CardSection
