import cn from 'classnames'
import { gsap } from 'gsap'
import * as React from 'react'

import { duration, stagger } from '../utils/animation'
import splitText from '../utils/splitText'
import Button from './Button'

type Props = {
  data: any
}

const Help = (props: Props) => {
  const comp: undefined | React.Ref<HTMLElement> = React.useRef()
  const { data } = props

  const [animateBg, setAnimateBg] = React.useState(false)

  React.useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const curRef = comp.current

      const [lines] = splitText('.js-splitText', curRef)

      gsap.from('.js-splitText-line', {
        y: '100%',
        opacity: 0,
        delay: 0.2,
        duration: duration,
        stagger: stagger,
        scrollTrigger: {
          trigger: lines.lines[0],
          start: 'top 90%',
        },
        onComplete: () => {
          setAnimateBg(true)
        },
      })
    }, comp)

    return () => {
      ctx.revert()
    }
  }, [])

  return (
    <section
      ref={comp}
      className={cn(
        'helpSection relative flex items-center justify-center overflow-hidden md:h-[90vh] px-4 md:px-8 py-20 md:py-40 bg-gradient-to-r from-white to-neutral-300',
        {
          'mt-32 md:mt-20': false,
        }
      )}
    >
      <div
        className={cn(
          'helpSection-gradient w-full bg-gradient-to-r from-transparent via-white to-transparent h-full absolute -left-1/2 top-0 z-1',
          { active: animateBg }
        )}
      />

      <div className="z-10 flex flex-col items-center">
        <h2 className="js-splitText text-4xl md:text-7xl max-w-[1024px]">{data.title}</h2>
        <p className="js-splitText mt-16 mb-10 max-w-[550px] text-center">{data.text}</p>

        {data.link && data.link.title && <Button data={data.link} className="hero-cta" />}
      </div>
    </section>
  )
}

export default Help
