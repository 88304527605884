import React from 'react'

import { PortableText } from '@portabletext/react'

export type SmallTextProps = {
  text: any
}

type Props = {
  data: SmallTextProps
  className?: string
  style?: React.CSSProperties
}

const TextSection = (props: Props) => {
  return (
    <div className="blockContent container mx-auto px-4 md:px-8 max-w-5xl">
      <PortableText value={props.data.text} />
    </div>
  )
}

export default TextSection
