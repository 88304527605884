import sanityClient from '@sanity/client'
import { substoreSettings } from '../utils/substores.config'

const CONTENT_PROJECT_ID = process.env.NEXT_PUBLIC_SANITY_CONTENT_PROJECT_ID
const DATASET = process.env.NEXT_PUBLIC_SANITY_DATASET

const sc = sanityClient({
  // Find your project ID and dataset in `sanity.json` in your studio project
  projectId: '94536s7h',
  dataset: `${DATASET || 'production'}`,
  useCdn: true,
  // useCdn == true gives fast, cheap responses using a globally distributed cache.
  // Set this to false if your application require the freshest possible
  // data always (potentially slightly slower and a bit more expensive).
})

export const listingsSanityClient = sc

const getContentSanityClient = () => {
  if (substoreSettings && CONTENT_PROJECT_ID) {
    return sanityClient({
      projectId: CONTENT_PROJECT_ID,
      dataset: `${DATASET || 'production'}`,
      useCdn: true,
    })
  } else {
    return sc
  }
}

const contentSanityClient = getContentSanityClient()

export default contentSanityClient
