import { SanityImageObject } from '@sanity/image-url/lib/types/types'
import cn from 'classnames'
import { useNextSanityImage } from 'next-sanity-image'
import Img from 'next/image'
import React from 'react'

import sanityClient from '../sanity'
import imageUrlFor from '../utils/imageUrlFor'

export interface SanityImage extends SanityImageObject {
  alt: string
}

type Props = {
  src: SanityImage
  width: number
  parentClassName?: string
  className?: string
  style?: React.CSSProperties
}

const Image = (props: Props) => {
  const imageProps = useNextSanityImage(sanityClient, props.src, {
    imageBuilder: () => imageUrlFor(props.src).ignoreImageParams().width(props.width).fit('fill'),
  }) as any

  return (
    <div className={cn('js-imgWrap overflow-hidden', props.parentClassName)}>
      <div className="img-inner relative">
        <Img
          {...imageProps}
          // src={imageUrlFor(props.src).ignoreImageParams().width(props.width).fit('fill').url()}
          className={cn('object-cover min-w-full', props.className)}
          alt={props.src.alt}
          placeholder="blur"
          blurDataURL={imageUrlFor(props.src).ignoreImageParams().width(100).blur(90).url()}
        />
      </div>
    </div>
  )
}

export default Image
