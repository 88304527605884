import { gsap } from 'gsap'
import * as React from 'react'

type Props = {
  data: any
}

const Quote = (props: Props) => {
  const comp: undefined | React.Ref<HTMLElement> = React.useRef()
  const { data } = props

  React.useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.to('.blockquote-box', {
        scrollTrigger: {
          trigger: comp.current,
          start: 'top 70%',
          end: 'top 20%',
          scrub: 1,
        },
        '--blockquote-box': '110%',
      })

      return () => {
        ctx.revert()
      }
    }, comp)
  }, [])

  return (
    <section ref={comp} className="quotes relative md:min-h-[80vh] px-4 md:px-8 py-20 md:py-40 bg-[#1D1D1D] text-white">
      <figure className="relative text-2xl md:text-4xl leading-normal md:leading-snug">
        <span className="block absolute z-0 md:max-w-[60vw] text-neutral-500">{data.text}</span>
        <blockquote className="blockquote-box relative z-10 md:max-w-[60vw] bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-green-500">
          {data.text}
        </blockquote>
        <figcaption className="pt-20 text-base">{data.author}</figcaption>
      </figure>
    </section>
  )
}

export default Quote
