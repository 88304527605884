import { PortableText } from '@portabletext/react'
import { Accordion as ReactAccordion } from '@szhsin/react-accordion'
import cn from 'classnames'
import { useRouter } from 'next/router'
import React from 'react'

import { trackCustom } from '../tracking'
import AccordionItem from './AccordionItem'

export type AccordionItemProps = {
  title: string
  text: any
}

type Props = {
  data: {
    list: AccordionItemProps[]
  }
}

const Accordion = ({ data }: Props) => {
  const router = useRouter()

  return (
    <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-8 mx-4 md:mx-8 my-20">
      <div className="col-span-1 md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3 xl:col-span-6 xl:col-start-4">
        <ReactAccordion transition transitionTimeout={500} allowMultiple>
          {data.list.map(el => {
            return (
              <AccordionItem
                key={el.title}
                header={el.title}
                onClick={() => {
                  trackCustom(`Accordion Toggled: ${router.pathname}`, { title: el.title })
                }}
              >
                <PortableText value={el.text} />
              </AccordionItem>
            )
          })}
        </ReactAccordion>
      </div>
    </div>
  )
}

export default Accordion
