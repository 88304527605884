import imageUrlBuilder from '@sanity/image-url'
import { SanityImageObject } from '@sanity/image-url/lib/types/types'

import sanity from '../sanity'

const imageBuilder = imageUrlBuilder(sanity)

const imageUrlFor = (source: SanityImageObject) => imageBuilder.image(source)

export default imageUrlFor
