import cn from 'classnames'
import { gsap } from 'gsap'
import React from 'react'

import { PortableText } from '@portabletext/react'

import { duration, stagger } from '../utils/animation'

export type ContactListEl = {
  title: string
  opening: string
  phone: string
}

export type ContactListBlock = {
  title: string
  text: any
  list: ContactListEl[]
}

type Props = {
  data: {
    list: ContactListBlock[]
  }
  className?: string
  style?: React.CSSProperties
}

const ContactListBlock = (props: Props) => {
  const comp: undefined | React.Ref<HTMLElement> = React.useRef()

  React.useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const targets = gsap.utils.toArray('.js-contactListEl')

      targets.forEach((el: any) => {
        gsap.from(el, {
          y: '100%',
          opacity: 0,
          duration: duration,
          stagger: stagger,
          ease: 'power2.easeInOut',
          scrollTrigger: {
            trigger: el,
            start: 'top 90%',
          },
        })
      })
    }, comp)

    return () => {
      ctx.revert()
    }
  }, [])

  return (
    <section ref={comp} className="grid grid-cols-1 xl:grid-cols-2 gap-4 md:gap-8 mx-4 md:mx-8 py-10">
      {props.data.list.map(el => (
        <div
          key={el.title}
          className="flex flex-col justify-between col-span-1 xl:[&:nth-of-type(1)]:border-t xl:[&:nth-of-type(2)]:border-t border-neutral-400 pt-8"
        >
          <div>
            <h3 className="text-3xl mb-10">{el.title}</h3>
            <PortableText value={el.text} />
          </div>

          <ul className="p-0">
            {el.list.map(listEl => (
              <li key={listEl.title} className="js-contactListEl grid xl:grid-cols-3 xl:gap-4 py-8 border-b border-neutral-400">
                <p>{listEl.title}</p>
                <p>{listEl.opening}</p>
                <a href={`tel:${listEl.phone}`}>{listEl.phone}</a>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </section>
  )
}

export default ContactListBlock
