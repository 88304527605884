import { TransmissionType } from '../types/filters'
import { FuelType } from '../types/fuelType'
import { VehicleType } from '../types/vehicleType'

export const serializeFuelType = (value: FuelType) => {
  switch (value) {
    case 'DIESEL':
      return 'Diesel'

    case 'ELECTRIC':
      return 'Elektrisk'

    case 'HYBRID':
      return 'Hybrid'

    case 'HYDROGEN':
      return 'Hydrogen'

    case 'PETROL':
      return 'Benzin'

    case 'PLUGIN_HYBRID':
      return 'Plugin hybrid'

    default:
      return ''
  }
}

export const serializeVehicleType = (value: VehicleType) => {
  switch (value) {
    case 'CONVERTIBLE':
      return 'Convertible'
    case 'COUPE':
      return 'Coupe'
    case 'HATCHBACK':
      return 'Hatchback'
    case 'MINIVAN':
      return 'Minivan'
    case 'PICKUP_TRUCK':
      return 'Pickup truck'
    case 'SEDAN':
      return 'Sedan'
    case 'SPORTS_CAR':
      return 'Sportsbil'
    case 'STATION_WAGON':
      return 'Station wagon'
    case 'SUV':
      return 'SUV'

    default:
      return ''
  }
}

export const serializeTransmissionType = (value: TransmissionType) => {
  switch (value) {
    case 'AUTOMATIC':
      return 'Automatisk'
    case 'MANUAL':
      return 'Manuel'

    default:
      return ''
  }
}
